<template>
	<!--begin::Modal - 내정보-->
	<div class="modal" tabindex="-1">
		<!--begin::Modal dialog-->
		<div class="modal-dialog modal-sm">
			<!--begin::Modal content-->
			<div class="modal-content">
        <div class="modal-header">
          <h3><i class="fa-regular fa-message-quote me-2"></i> Quot Image Source</h3>
        </div>
				<!--begin::Modal body-->
				<div class="modal-body p-pt-3" v-if="model.citeInfo">

          <!-- quotation start -->
          <div class="quot-wrap" style="height: fit-content;">
            <!-- image area start -->
            <div style="position: relative; overflow: hidden; width: 100%; height: 100%; max-height: 363px; min-height: 242px; text-align: center;">
              <img :src="model.citeInfo.thumUrl" style="position: absolute; left: 50%; top: 0; max-width: 100%; max-height: 100%; width: 100%; object-fit: contain; transform: translateX(-50%); display: block;" />
            </div>
            <!-- image area end -->
            <!-- text area start -->
            <div style="text-align: center;margin-top: 0; padding: 4px 0;">
              <div v-if="model.quotLen==='s'" style="font-size: 11px;">Image from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>
              <div v-if="model.quotLen==='m'" style="font-size: 11px;">Image by {{ model.citeInfo.crtrNm }} from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>
              <div v-if="model.quotLen==='l'" style="font-size: 11px; display: flex; justify-content: center;">
                &lt;&nbsp;{{ model.citeInfo.crtrNm }},&nbsp;<div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-break: break-all;">"{{ model.citeInfo.titl }}"</div>,&nbsp;<a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated&nbsp;&gt;</a>
              </div>
            </div>
            <!-- text area end -->
          </div>
          <!-- quotation end -->

          <div class="px-3">
            <textarea class="html-area w-100 p-2 fs-9 fw-lighter border-primary" v-model="model.htmlTag"></textarea>
          </div>

          <div class="mx-3 mb-5">
            <p class="quot-desc text-dark fs-8 fw-lighter indt-titl my-0">
              ※ The citations provided are for editorial use only and may not be used other than by reference in internet media or personal blogs.
            </p>
            <p class="quot-desc text-dark fs-8 fw-lighter indt-titl my-0">
              ※ The style of text and image can be adjusted, except the original website name and the URL link.
            </p>
            <p class="m-0 fs-8">※ Try it from <a class="ms-1 btn btn-sm px-1 py-0 icon-codepen" href="https://codepen.io/method76/pen/RwXOjvm" target="_blank"></a></p>
          </div>

        </div>
				<!--end::Modal body-->

        <!-- modal footer -->
        <div class="modal-footer d-flex">

          <div v-if="model.quotLen" class="btn-group m-0">

            <input
              id="s"
              v-model="model.quotLen"
              value="s"
              autocomplete="off"
              class="btn-check"
              type="radio"
              :checked="model.quotLen === 's'"
            />
            <label for="s" class="btn btn-outline-primary px-2 text-center" @click="toSmamQuot()">
              Short
            </label>

            <input
              id="m"
              v-model="model.quotLen"
              value="m"
              autocomplete="off"
              class="btn-check"
              type="radio"
              :checked="model.quotLen === 'm'"
            />
            <label for="m" class="btn btn-outline-primary px-2 text-center" @click="toMedmQuot($event)">
              Normal
            </label>

            <input
              id="l"
              v-model="model.quotLen"
              value="l"
              autocomplete="off"
              class="btn-check"
              type="radio"
              :checked="model.quotLen === 'l'"
            />
            <label for="l" class="btn btn-outline-primary px-2 text-center" @click="toFullQuot($event)">
              Full
            </label>

          </div>

          <div class="flex-grow-1 btn-righ-area text-end m-0">
            <div class="btn btn-primary d-inline-flex" @click="onCopyClck($event)">
              <i class="fa-solid fa-scissors me-2"></i><div>Copy</div>
            </div>
          </div>

        </div>
        <!-- modal footer -->

			</div>
			<!--end::Modal content-->

      <btn-close-top-right @onCloseClick="emit('closeModal')" />

		</div>
		<!--end::Modal dialog-->
	</div>
	<!--end::Modal - 내정보-->

</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { user_stor } from '@/stor/user_stor'
import { cnst_stor } from '@/stor/cnst_stor'
import BtnCloseTopRight from '@/view/btns/BtnCloseTopRight.vue'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const emit  = defineEmits(['closeModal'])
const cnst = cnst_stor()
const user = user_stor()
const prop = defineProps(['citeInfo'])
let sHtml
let mHtml
let lHtml
const model = reactive({
  citeInfo: null,
  quotLen: 'm',
  htmlTag: ''
})

onMounted(() => {
  model.citeInfo = prop.citeInfo
  model.citeInfo.thumUrl = model.citeInfo.imgUrl.replace("-1200", "-600").replace(".avif", ".webp")
  sHtml =
    '<!-- quotation start -->\n' +
    '<div style="height: fit-content;">\n' +
    '  <!-- image area start -->\n' +
    '  <div style="position: relative; overflow: hidden; width: 100%; height: 100%; max-height: 363px; min-height: 242px; text-align: center;">\n' +
    `    <img src="${model.citeInfo.thumUrl}" style="position: absolute; left: 50%; top: 0; max-width: 100%; max-height: 100%; width: 100%; object-fit: contain; transform: translateX(-50%); display: block;" />\n` +
    '  </div>\n' +
    '  <!-- image area end -->\n' +
    '  <!-- text area start -->\n' +
    '  <div style="text-align: center;margin-top: 0; padding: 4px 0;">\n' +
    '    <div style="font-size: 11px;">Image from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>\n' +
    '  </div>\n' +
    '  <!-- text area end -->\n' +
    '</div>\n' +
    '<!-- quotation end -->'
  mHtml =
    '<!-- quotation start -->\n' +
    '<div style="height: fit-content;">\n' +
    '  <!-- image area start -->\n' +
    '  <div style="position: relative; overflow: hidden; width: 100%; height: 100%; max-height: 363px; min-height: 242px; text-align: center;">\n' +
    `    <img src="${model.citeInfo.thumUrl}" style="position: absolute; left: 50%; top: 0; max-width: 100%; max-height: 100%; width: 100%; object-fit: contain; transform: translateX(-50%); display: block;" />\n` +
    '  </div>\n' +
    '  <!-- image area end -->\n' +
    '  <!-- text area start -->\n' +
    '  <div style="text-align: center;margin-top: 0; padding: 4px 0;">\n' +
    `    <div style="font-size: 11px;">Image by ${model.citeInfo?model.citeInfo.crtrNm:''} from <a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a></div>\n` +
    '  </div>\n' +
    '  <!-- text area end -->\n' +
    '</div>\n' +
    '<!-- quotation end -->'
  lHtml =
    '<!-- quotation start -->\n' +
    '<div style="height: fit-content;">\n' +
    '  <!-- image area start -->\n' +
    '  <div style="position: relative; overflow: hidden; width: 100%; height: 100%; max-height: 363px; min-height: 242px; text-align: center;">\n' +
    `    <img src="${model.citeInfo.thumUrl}" style="position: absolute; left: 50%; top: 0; max-width: 100%; max-height: 100%; width: 100%; object-fit: contain; transform: translateX(-50%); display: block;" />\n` +
    '  </div>\n' +
    '  <!-- image area end -->\n' +
    '  <!-- text area start -->\n' +
    '  <div style="text-align: center;margin-top: 0; padding: 4px 0;">\n' +
    '    <div style="font-size: 11px; display: flex; justify-content: center;">\n' +
    `      &lt;&nbsp;${model.citeInfo.crtrNm},&nbsp;<div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-break: break-all;">"${model.citeInfo.titl}"</div>,&nbsp;<a href="https://www.gen8.id" target="_blank" style="text-decoration: none; color: black;">Generated</a>&nbsp;&gt;\n` +
    '    </div>\n' +
    '  <!-- text area end -->\n' +
    '</div>\n' +
    '<!-- quotation end -->'
  model.htmlTag = mHtml
})

function onCopyClck() {
  window.navigator.clipboard
    .writeText(model.htmlTag)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The HTML TEXT to embed has<br/>been copied to the clipboard.'
      )
    })
}

/**
 * /ress/pmpt/imgs/241025/16RNe29tp1H/scewdvzsw7zafnfsl8kst.avif
 * /ress/pmpt/imgs/241025/16RNe29tp1H/scewdvzsw7zafnfsl8ksm.webp
 */
function toMedmQuot(e) {
  model.quotLen = 'm'
  model.htmlTag = mHtml
}

function toFullQuot(e) {
  model.quotLen = 'l'
  model.htmlTag = lHtml
}

function toSmamQuot(e) {
  model.quotLen = 's'
  model.htmlTag = sHtml
}
</script>

<style scoped>
.modal-dialog {
  overflow-x: hidden;
}
.modal-header {
  height: 40px;
  padding: 8px 10px 10px;
}
.modal-header h3 {
  line-height: 1;
}
.modal-content {
  height: 100%;
}
.quot-wrap {
}
.modal-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 1rem;
  height: 50px;
}
.modal-footer .btn-group {
  height: 34px;
}
.btn-group .btn {
  height: 100%;
  line-height: 1;
  width: 72px;
  padding-top: 7px;
}
.btn-righ-area .btn {
  padding-top: 7px;
  font-size: 1.1rem;
}
.html-area {
  border: 2px solid #595959;
  border-radius: 6px;
  height: 100px;
}
.quot-desc {
  letter-spacing: -0.5px;
}
.btn-check:checked + .btn {
  background-color: rgb(0, 169, 204) !important;
  color: white !important;
}
.icon-codepen {
  border-radius: 5px;
  width: 86px;
  height: 19px;
  background-color: #ffcc00;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('@/assets/img/icon/icon-codepen-black.webp');
}
.img-wrap img {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 383px;
  height: 100%;
  max-height: 383px;
}

@media (max-width: 767.98px) {
  .modal-dialog {
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 600px;
    height: 700px;
  }
}
</style>
