<template>
  <div class="row ctnt-wrap w-100 gx-0">
    <!-- Left col : image display column -->
    <div class="col col-12 col-lg-8 col-xxl-9 col-xxxl-10 col-left position-relative">
      <!-- image content wrapper (full screen area) -->
      <div id="img-ctnt-wrap" class="img-ctnt-wrap position-relative">

        <zoomer-with-splide
          id="zoom-wrap"
          ref="zoomWrap"
          v-if="model.slctIdx > -1"
          :imgList="model.imgList"
          :grup="model.grup"
          :selectedIndex="model.slctIdx"
          @onImgLoad="onImgLoad"
          @currItemId="currItemId"
          @setFullScrn="setFullScrn"
          @copyClbdSmalCite="copyClbdSmalCite"
          @copyClbdMidmCite="copyClbdMidmCite"
        />

        <div v-if="model.slctIdx > -1" class="scnd-sprt-titl pos-abs-top-left text-shadow-sm">
          <i class="fa-regular fa-microchip-ai svg-shadow-sm me-2"></i>{{ model.grup.titl }}
        </div>

        <!-- PC: small option button groups -->
        <div v-if="model.grup" class="pc-bttm-area text-white pos-abs-bottom-left svg-shadow-sm justify-content-start fs-8">
          <!-- PC: 바텀 로고 -->
          <div class="lb-logo-gen8">
            <img src="/aset/icon-generated-red.svg" />
          </div>
          <!-- PC: 바텀 로고 -->
          <!-- PC: 바텀 인용 -->
          <div class="d-flex flex-grow-1 text-center">
            <div class="ellipsis mx-auto">
              &lt; {{ model.grup.crtrNm }},&nbsp;"{{ model.grup.titl }}", online image, {{ model.grup.aprvDttm?isoDateToOnlyYear4(model.grup.aprvDttm):'' }}, Generated &gt;
            </div>
          </div>
          <!-- PC: 바텀 인용 -->
          <!-- PC: 바텀 최소화 -->
          <a role="button" @click="setFullScrn(false)" class="btn btn-lg btn-flex btn-max btn-outline-white shadow-md">
            <i class="fa-solid fa-arrows-minimize"></i>
          </a>
          <!-- PC: 바텀 최소화 -->
        </div>

        <!-- key nav helper button group -->
        <div class="kybd-wrap">
          <div class="ic-arow-ud text-end">
            <img src="@/assets/img/icon/ic-arrw-ud.webp" />
            <div class="small text-white text-shadow-sm mt-2 text-center">Use arrow<br/>keys to<br/>navigate</div>
          </div>
          <div class="ic-arow-lr text-center d-none">
            <img src="@/assets/img/icon/ic-arrw-lr.webp" />
            <div class="small text-white text-shadow-sm mt-2 text-center">Use arrow keys to navigate</div>
          </div>
        </div>
        <!-- key nav helper button group -->

        <spnr-no-block v-if="model.loading" theme="white" class="absolute" />

      </div>
      <!-- image content wrapper (full screen area) -->
    </div>
    <!-- Left col : image display column -->

    <!-- Right col : image description column -->
    <div
      class="col col-12 col-lg-4 col-xxl-3 col-xxxl-2 img-detail col-right position-relative"
      :class="prop.mode?'full':''"
      v-if="model.grup"
    >
      <hr class="hr-handle w-35 d-block d-lg-none" />
      <div class="w-100 text-start left-cornered d-none">
        <h5 class="art-title">{{ model.grup.titl }}</h5>
      </div>

      <div class="table-wrap">
        <table class="table white work-info-pane">
          <colgroup>
            <col style="width: 160px;max-width: 50%;" />
            <col style="width: auto;min-width: 50%;" />
          </colgroup>
          <tbody>
            <tr class="border-0">
              <td colspan="2" class="col-crtr p-0 pe-2 bg-f3f3f3 border-0">
                <div class="row-rels d-flex">
                  <div class="fw-bold text">
                    {{ model.grup.rgstStep===ArtRgstStep.REQUESTED?'Submitted at':'Released' }}
                  </div>
                  <div class="ms-2 text">
                    {{ model.grup.rgstStep===ArtRgstStep.REQUESTED?isoDateToShortYear4(model.grup.sbmtDttm):isoDateToShortYear4(model.grup.aprvDttm) }}
                  </div>
                  <div class="icon">
                    <i v-if="model.grup.rgstStep===ArtRgstStep.REQUESTED" class="fa-regular fa-hand-wave ms-2 text-primary"></i>
                    <i v-if="model.grup.rgstStep!==ArtRgstStep.REQUESTED" class="fa-solid fa-plane-departure ms-2 text-primary"></i>
                  </div>
                </div>

                <crtr-info-full-horz v-if="model.crtrInfo" :data="model.crtrInfo" class="row-crtr-info" algn="end" />

              </td>
            </tr>
            <tr class="bb-solid bt-solid">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-message-smile"></i>
                    </span>
                    <span class="text">
                    Creator's Message
                  </span>
                  </div>
                  <div class="th-innr-right with-btn">
                    <a
                      @click="showRprtModal"
                      class="btn btn-sm btn-white btn-outline-light-gray d-flex btn-rept float-right w-33"
                    >
                      <span>
                        <i class="fa-solid fa-flag"></i>
                      </span>
                      <span class="btn-txt me-2 fw-medium">
                        Report
                      </span>
                    </a>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <td colspan="2"
                class="td-crtr-msg text-start">
                {{ model.grup.crtrMesg }}
              </td>
            </tr>

            <!-- generated with & media quality -->
            <tr class="bb-solid bt-solid">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-screwdriver-wrench"></i>
                    </span>
                    <span class="text">
                      Generated with
                    </span>
                  </div>
                  <div class="th-innr-right font-lg-wrap">
                    <span class="icon gen-tool">
                      <img
                        v-if="model.grup.prvdCd"
                        :src="'/aset/img/brnd/' + model.grup.prvdCd + (model.grup.prvdCd==='FXVR'?'.svg':'.webp')"
                      />
                    </span>
                    <span class="text">
                    {{
                      model.grup.prvdCd
                        ? cnst.getCodeName("GNTL", model.grup.prvdCd)
                        : "Not input yet"
                    }}
                    ({{ model.grup.modlNm ? model.grup.modlNm : cnst.getCodeName(model.grup.prvdCd, model.grup.modlCd) }})
                    </span>
                  </div>
                </div>
              </th>
            </tr>

            <tr>
              <th>
                <div class="th-innr justify-content-between">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </span>
                    <span class="text ellipsis">
                      Image Quality (AVG)
                    </span>
                  </div>
                </div>
              </th>
              <td class>
                <div class="th-innr-right">
                  <span class="badge rounded-pill m-0 fs-8 imgs-qlty" v-if="model.grup.avrgFcus">
                    {{ Math.round(model.grup.avrgFcus) }}% focus / {{ pxNum2StrLong(model.grup.avrgPixl) }} / {{ byteNum2StrShrt(model.grup.avrgByts) }}
                  </span>
                </div>
              </td>
            </tr>

            <tr class="bb-solid">
              <th>
                <div class="th-innr-left">
                  <div class="icon">
                    <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                  </div>
                  <div class="text">Additional Upscaler</div>
                </div>
              </th>
              <td class="text-end fw-bold">
                {{
                  model.grup.upscCd
                    ? cnst.getCodeName("MJUP", model.grup.upscCd)
                    : model.grup.upscNm
                      ? model.grup.upscNm
                      : "Not used"
                }}
              </td>
            </tr>
            <!-- generated with & media quality -->

            <!-- content view grade -->
            <tr class="bb-solid">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <img src="@/assets/img/icon/ic-ctnt-rate.webp" class="ic-esrb" />
                    </span>
                    <span class="text">
                      Content Ratings
                    </span>
                  </div>
                  <div class="th-innr-right font-lg-wrap">
                    <template v-if="model.grup.ctntGradGrupFinl === ContentGrade.EVERYONE">
                      <span class="icon py-0">
                        <img src="@/assets/img/icon/ic-prop-cap.svg" />
                      </span>
                      <span class="text">
                        Everyone
                      </span>
                    </template>
                    <template v-if="model.grup.ctntGradGrupFinl === ContentGrade.TEEN">
                      <span class="icon py-0">
                        <img src="@/assets/img/icon/ic-MAAG0001.webp" />
                      </span>
                      <span class="text">
                        Teen
                      </span>
                    </template>
                    <template v-if="model.grup.ctntGradGrupFinl === ContentGrade.ADULT">
                      <span class="icon py-0">
                        <img src="@/assets/img/icon/ic-MAAG0002.webp" />
                      </span>
                      <span class="text">
                        Adult
                      </span>
                    </template>
                  </div>
                </div>
              </th>
            </tr>
            <!-- content view grade -->

            <tr>
              <th class="ellipsis">
                <div class="th-innr-left">
                  <div class="icon">
                    <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                  </div>
                  <div class="text">Celebrities, Real People</div>
                </div>
              </th>
              <td>
                {{ (model.grup.celbCtntGrupCrtr) ? '<i class="fa-solid fa-circle-check"></i>' : '-' }}
              </td>
            </tr>
            <tr class="bb-solid">
              <th>
                <div class="d-flex justify-content-between">
                  <div class="th-innr-left">
                    <div class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </div>
                    <div class="text">Licensed Characters</div>
                  </div>
                </div>
              </th>
              <td>
                {{ model.grup.lcsdCtntGrupCrtr ? '<i class="fa-solid fa-circle-check"></i>' : '-' }}
              </td>
            </tr>

            <!-- pricing -->
            <tr class="bb-solid" :class="(model.grup.celbCtntGrupCrtr===true || model.grup.lcsdCtntGrupCrtr===true) ? 'bt-solid' : ''">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-money-check-dollar"></i>
                    </span>
                    <span class="text">
                      Pricing
                    </span>
                  </div>
                  <div class="th-innr-right with-btn">
                    <select
                      v-if="model.crcyList && model.crcyList.length>0"
                      class="form-select form-select-sm order-by text-center"
                      v-model="model.slctCrcy"
                      @change="chngCrcy($event)"
                    >
                      <template v-for="(item, idx) in model.crcyList" :key="idx">
                        <option :value="item">
                          {{ item }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div class="d-flex justify-content-between">
                  <div class="th-innr-left">
                    <div class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </div>
                    <div class="text">Prompt</div>
                  </div>
                </div>
              </th>
              <td class="font-lg-wrap">
                <div class="th-innr-right justify-content-between">
                  <span class="bdge-pmpt badge rounded-pill m-0 me-1 d-flex">
                    <span v-if="model.grup.pmptImgCnt > 0" class="d-flex">
                      <span>
                        <i class="fa-regular fa-image"></i>
                      </span>
                      <span class="mx-1"> + </span>
                    </span>
                    <span>{{ model.grup.pmptLeng }} letters</span>
                  </span>
                  <span class="text">
                    {{ model.grup.pmptPricAmt ? currFormat.format(model.grup.pmptPricAmt / 100) : "-" }}
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <th>
                <div class="d-flex justify-content-between">
                  <div class="th-innr-left">
                    <div class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </div>
                    <div class="text">Generated Image</div>
                  </div>
                </div>
              </th>
              <td class="font-lg-wrap">
                <div class="th-innr-right justify-content-between">
                  <span class="badge rounded-pill img-cnt m-0 me-1">
                    {{ model.grup.mdiaCnt }} pcs/ea
                  </span>
                  <span class="text">
                    {{ model.grup.imgPricAmt ? currFormat.format(model.grup.imgPricAmt / 100) + "" : "Free" }}
                  </span>
                </div>
              </td>
            </tr>
            <!-- pricing -->

            <tr class="bb-solid" role="button" @click="toglKywdShow">
              <th class="kywd-btn-container" colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-tags"></i>
                    </span>
                    <span class="text">
                      Keywords
                    </span>
                  </div>
                  <div class="th-innr-right with-btn">
                    <span class="badge rounded-pill bdge-kywd-cnt">
                      {{ model.grup.kywdGrupFinlPblc ? model.grup.kywdGrupFinlPblc.length : 0 }}
                    </span>
                    <btn-fold @btnFoldChanged="onBtnFoldChng" :opened="model.kywdOpen"
                        @click="toglKywdShow" />
                  </div>
                </div>
              </th>
            </tr>

            <tr v-show="model.kywdOpen" class="">
              <td colspan="2" class="td-kywd-wrap">
                <div
                  class="w-100 kywd-wrap"
                  v-if="model.grup.kywdGrupFinlPblc && model.grup.kywdGrupFinlPblc.length > 0"
                >
                  <span
                    class="badge emboss bg-white bdge-kywd shadow-sm"
                    v-for="(item, idx) in model.grup.kywdGrupFinlPblc"
                    :key="idx"
                  >
                    {{ item }}
                  </span>
                </div>
                <div
                  class="w-100 kywd-wrap"
                  v-if="!model.grup.kywdGrupFinlPblc || model.grup.kywdGrupFinlPblc.length < 1"
                >
                  No Keywords
                </div>
              </td>
            </tr>
            <tr v-if="model.grup && model.grup.purchased" class="bt-solid">
              <td colspan="2" class="bg-light-gray">
                <!-- download button -->
                <div class="btn-dnld mx-4 my-3 position-relative">
                  <a
                    @click="showDnldNoti(model.grup.grupId)"
                    class="btn btn-lg btn-dnld btn-flex btn-outline btn-primary shadow-md">
                    <i class="fa-regular fa-folder-arrow-down ms-1 me-2"></i>Download</a>
                  <div class="note-pcse-item" v-if="model.grup && model.grup.purchased">Purchased</div>
                </div>
                <!-- download button -->
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
    <!-- Right col : image description column -->

    <!-- hidden download form + iframe wrap -->
    <div class="d-none">
      <form name="dnldForm2" method="post" target="hidn-frme">
        <input type="hidden" name="tt" :value="model.tokn" />
      </form>
      <iframe name="hidn-frme"></iframe>
    </div>
    <!-- hidden download form + iframe wrap -->

  </div>
</template>

<script setup>
import ZoomerWithSplide from "@/view/cpnt/ZoomerWithSplide.vue"
import { onMounted, reactive, ref } from 'vue'
import { user_stor } from "@/stor/user_stor"
import {
  byteNum2StrShrt, isoDateToOnlyYear4,
  isoDateToShortYear4,
  pxNum2StrLong
} from '@/util/comn_util'
import BtnFold from "@/view/btns/BtnFold.vue"
import { API_ROOT, ArtRgstStep, BrwsStrgKey, ContentGrade } from '@/util/comn_cnst'
import { mdal_stor } from '@/stor/mdal_stor'
import { cnst_stor } from '@/stor/cnst_stor'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'
import CrtrInfoFullHorz from '@/view/cpnt/CrtrInfoFullHorz.vue'
import { i18n } from '@/lcal/i18n'
import axis_cstm from '@/util/axis_cstm'
import FileDownload from 'js-file-download'

const WEBSITE_HOST = import.meta.env.VITE_APP_HOST
const { t } = i18n.global
const zoomWrap = ref()
const strgPath = import.meta.env.VITE_APP_BUCKET_HOST + "/ress/"
const cnst = cnst_stor()
const mdal = mdal_stor()
const prop = defineProps(["data", "mode"])
const emit = defineEmits(["currItemId", "btnFoldChanged", "showRprtMdal"])
const user = user_stor()
const model = reactive({
  loading: false,
  grup: null,
  imgList: [],
  pricList: [],
  pricData: {},
  slctIdx: -1,
  slctCrcy: null,
  crcyList: [],
  kywdOpen: true,
  url: null,
  currUrl: window.location.href,
  tokn: sessionStorage.getItem(BrwsStrgKey.FO_JWT)
})

let fullScrnElem
let currFormat

onMounted(() => {
  model.url = location.href.split('/view')[0]
  model.slctIdx = prop.data.index
  model.grup = prop.data.grup
  model.grup.kywdGrupFinlPblc = model.grup.kywdGrupFinlPblc ? model.grup.kywdGrupFinlPblc.sort() : []
  model.crtrInfo = {
    crtrId: model.grup.crtrId,
    crtrNm: model.grup.crtrNm,
    crtrGrad: model.grup.crtrGrad,
    natnCtry: model.grup.natnCtry,
    thumUrl: model.grup.thumUrl,
  }
  prop.data.imgList.forEach((it) => {
    it.pblcWtmkUrl = strgPath + it.pblcWtmkUrl.replace("/ress/", "")
      .replace("ress/pmpt", "pmpt")
    model.imgList.push(it)
  })
  model.pricList = prop.data.pricList
  model.crcyList = prop.data.crcyList
  const slct = sessionStorage.getItem("crcy") ? sessionStorage.getItem("crcy") : null
  // console.log('slct', JSON.stringify(model.slct))
  if (slct === null || slct==='') {
    model.slctCrcy = 'USD'
    sessionStorage.setItem("crcy", model.slctCrcy)
  } else {
    model.slctCrcy = slct
  }
  currFormat = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: model.slctCrcy
  })
  fullScrnElem = document.getElementById("img-ctnt-wrap")
  document.addEventListener("fullscreenchange", onFullScrnChng)

})

function getWtmkUrl() {
  return zoomWrap.value.getWtmkUrl()
}

function onImgLoad(val) {
  model.loading = val
}

function showRprtModal() {
  if (!user.isSignedIn) {
    mdal.confirm(
      "Sign in is required.<br/>Would you like to sign in?",
      function() {
        user.closImgDetlPopUp()
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        location.href =
          "/view/sign/in?r=" + encodeURIComponent(`/view/pmpt/imgs/${model.grup.grupId}${imgPath}`)
      },
      "<i class='fa-solid fa-check me-2'></i> Sign in"
    )
  } else {
    emit('showRprtMdal')
  }
}

function onFullScrnChng(e) {
  // document.fullscreenElement will point to the element that
  // is in fullscreen mode if there is one. If there isn't one,
  // the value of the property is null.
  try {
    if (!fullScrnElem) {
      fullScrnElem = document.getElementById("img-ctnt-wrap")
    }
    if (document.fullscreenElement) {
      fullScrnElem.classList.add("fullscreen")
      document.querySelector('.kybd-wrap').classList.add('blink')
      setTimeout(function() {
        document.querySelector('.kybd-wrap').classList.remove('blink')
        document.querySelector('.kybd-wrap').classList.add('d-none')
      }, 4000)
    } else {
      console.log("Leaving fullscreen mode.")
      fullScrnElem.classList.remove("fullscreen")
    }
  } catch (e) {
    console.error('[FULLSCRN] e', e.message)
  }
}

function setFullScrn(setFull) {
  console.log("[IMGDETLPANE] set full scrn to", setFull)
  // 내장 객체임
  if (document.fullscreenElement) {
    // exitFullscreen is only available on the Document object.
    document.exitFullscreen()
  } else {
    fullScrnElem.requestFullscreen()
  }
}

function currItemId(itemId, slctIdx) {
  emit("currItemId", itemId)
  model.slctIdx = slctIdx
}

function toglKywdShow() {
  model.kywdOpen = !model.kywdOpen
}

function onBtnFoldChng(opened) {
  model.kywdOpen = opened
  if (model.kywdOpen === true) {
     const inHegh = document.querySelector("#img-modal-body").clientHeight
     setTimeout(function () {
       document.getElementById("modal-content").scrollTo(0, inHegh)
     }, 100)
  }
}

function showDnldNoti(grupId) {
  const dnldUrl = API_ROOT + `prvt/pcse/dnld/grup/${grupId}`
  axis_cstm()
    .get(dnldUrl, {responseType: 'blob'})
    .then((res) => {
      FileDownload(res.data, `generated-${model.grup.grupId}.zip`)
    })
    .finally(function () {
      setTimeout(function () {
        mdal.stopMainLodr()
      }, 3500)
    })

  console.log(`[DNLD] ${dnldUrl}`)
  mdal.toast(
    t("RQST_DOWNLOAD_WAIT")
  )
}

function chngCrcy(e) {
  const crcy = e.target.value
  sessionStorage.setItem("crcy", crcy)
  console.log("e.crcy", crcy)
}

defineExpose({ setFullScrn, getWtmkUrl })
</script>

<style scoped>
.ctnt-wrap {
  background-color: rgb(33, 37, 41);
  height: 100vh;
}
.img-ctnt-wrap {
  background-image: url(@/assets/img/bg/bg-leather-sqre-b.avif);
  background-size: cover;
}

.col-right {
  background-color: white;
  margin-bottom: 0;
  padding-bottom: 120px;
}
.ic-esrb {
  height: 26px !important;
}
.cell-kywd + .badge {
  margin: 2px 0 0 0 !important;
}
.cell-kywd .img-wrap svg {
  height: 20px;
  padding-top: 5px;
}

.bdge-rate.teen .icon img {
  height: 21px;
}

.icon .svg-l {
  height: 19px;
}

.table tbody th .badge, .table tbody td .badge {
  background-color: white;
  height: 24px !important;
  padding: 2px 4px 3px 4px !important
}
/*
.imgs-qlty {
  padding-right: 6px !important;
}
*/
.imgs-qlty > * {
  float: right;
}
.fa-turn-down-right {
  width: 15px !important;
  height: 15px !important;
}
.fa-tags {
  width: 18px !important;
  height: 18px !important;
}
.table {
  table-layout: fixed;
  background-color: #f3f3f3;
}

.table tbody th .img-wrap, .table tbody td .img-wrap {
  width: 26px;
  height: 26px;
  padding: 0;
  line-height: 26px;
  margin-right: 1px;
  text-align: center;
  vertical-align: middle;
}

.table > * > * .img-wrap > img, .table > * > * .img-wrap > svg {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

.table tbody th svg, .table tbody td svg {
  width: 20px;
  height: 20px;
}

.table tbody th .badge {
  border-width: 2px;
  border-color: #999;
}
.table tbody th .badge svg {
  width: 17px;
  height: 17px;
}

.table th {
  text-align: start;
  background-color: #f9f9f9;
}

.table th[colspan="2"] {
  background-color: #ededed;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
}

.table.white tbody th:not([colspan="2"]) {
  background-color: #f3f3f3;
}

.table > tbody > tr > td[colspan="2"] {
  text-align: end;
}

.table > * > * > *[colspan="2"] {
  border-top: 1px dashed gray;
}

.table thead td {
  padding-bottom: 0.5rem;
}

.table tbody tr:last-child > * {
  border-bottom: 0;
}
.table * tr > * {
  font-size: 0.8rem !important;
  padding: 4px 8px;
}

.table * tr td:last-child {
  font-size: 0.9rem !important;
}

.td-crtr-msg {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-top: 1px solid gray !important;
  line-height: 1.5 !important;
}

.th-innr {
  vertical-align: middle;
  display: flex;
  height: 30px;
}

.th-innr * {
  vertical-align: middle;
}

.th-innr .text {
  font-size: 0.8rem;
  font-weight: bold;
}
.bdge-kywd-cnt {
  margin-top: 2px !important;
  border-color: #595959 !important;
  font-weight: bold;
}
.fa-money-check-dollar {
  vertical-align: middle;
  width: 24px !important;
  height: 21px !important;
}

.th-innr-left {
  display: flex;
  flex-grow: 1;
  text-align: start;
  line-height: 29px !important;
}

.th-innr-left .icon {
  margin-right: 5px;
}

.th-innr-left .icon > * {
  height: 24px;
  max-width: 24px;
}

.th-innr .icon.prop-cap, .th-innr .icon.gen-tool{
  padding-top: 0 !important;
}

.th-innr-left .icon svg {
  max-width: 24px;
}

.th-innr-right {
  display: flex;
  justify-content: end;
  font-weight: bold;
  height: 22px;
  line-height: 22px;
}

.th-innr .th-innr-right {
  display: flex;
  justify-content: end;
  line-height: 24px;
  height: 30px;
}
.btn.btn-arrow-outline {

}
.table tbody .th-innr .th-innr-right .badge.rounded-pill {
  min-width: 28px;
  height: 28px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 0.9rem !important;
  line-height: 20px;
}

.th-innr .th-innr-right:not(.with-btn) .text {
  padding-top: 1px;
}

.th-innr-right .icon, .th-innr .th-innr-right:not(.with-btn) .text {
  height: 22px !important;
  margin-top: 2px;
}

.th-innr-right .icon img, .th-innr-right .icon svg {
  height: 20px !important;
}
.btn-dnld svg {
  margin-top: 3px;
}
.font-lg-wrap .text {
  margin-left: 6px;
  font-size: 0.9rem !important;
  color: #0087a3;
}

.imgs-qlty {
  margin-top: 2px !important;
}

.fa-screwdriver-wrench {
  width: 18px !important;
  height: 18px !important;
}

.table tbody * .badge.rounded-pill {
  font-size: 0.7rem !important;
  padding: 2px 6px 2px 6px !important;
  height: 22px !important;
  font-weight: 500;
}
.kybd-wrap {
  display: none;
}

.kybd-wrap .ic-arow-ud img {
  height: 120px;
}

.kybd-wrap .ic-arow-lr img {
  width: 120px;
}

.form-select-sm {
  height: 29px;
  font-weight: bold;
}

.flag {
  width: 30px;
  height: 22px;
  border-radius: 3px;
  margin-left: 4px;
  margin-right: 5px;
}

.col-crtr {
  height: 54px;
  vertical-align: bottom;
  padding-bottom: 4px !important;
}

.crtr-info {
  line-height: 24px;
  font-size: 1rem;
}

.crtr-innr-left {
  margin: 0;
  padding: 0 0 0 0;
}
.crtr-innr-left .flag {
  margin-top: 2px;
}
.crtr-innr-left > *:not(.flag) {
  margin-top: 0;
}

.table tbody th,
.table tbody td {
  height: 40px;
  padding: 4px 8px;
  background-color: transparent !important;
}

.table th[colspan="2"] {
  background-color: white !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.bdge-kywd {
  text-transform: lowercase;
}
.crtr-txt-wrap svg {
  margin-top: 5px;
  margin-right: 6px;
}

.note-pcse-item {
  font-size: 0.7rem;
  font-weight: 500;
  background-color: #ffcc00;
  color: #444;
  width: fit-content;
  padding: 1px 4px 1px;
  position: absolute;
  height: fit-content;
  line-height: 1;
  top: 2px;
  right: 6px;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

th .badge,
.bdge-pmpt {
  cursor: initial;
  font-size: 0.9rem;
  min-width: 26px;
  height: 26px;
  padding: 3px 3px !important;
  line-height: 18px;
  background-color: white;
}
.table {
  letter-spacing: -0.5px;
}
.table tbody > * > * {
  font-size: 1rem;
}
.txt-crtr {
  color: #585858;
  padding-top: 1px;
}
.dark-indigo {
  color: #0D47A1;
}
.btn-rept {
  width: 100%;
  padding: 3px 3px;
  line-height: 18px;
  height: 29px;
}

.btn-rept svg {
  color: rgb(0, 135, 163);
  height: 15px !important;
  margin: 0 2px 0 0 !important;
}

.btn-abs-pos-rt > * > svg {
  height: 100%;
}

.img-cnt {
  min-width: 20px;
  padding-top: 1px;
  padding-bottom: 0;
  border-width: 1px;
}
.row-rels {
  padding-left: 9px;
}
.row-rels .text {
  line-height: 26px;
}
.row-rels .icon svg {
  margin-top: 5px;
  height: 13px;
  color: #0087a3 !important;
}
.art-title {
  max-height: 100px;
}

.td-kywd-wrap {
  padding: 6px 0 6px 6px !important;
}

.kywd-wrap {
  display: ruby;
}

.table tbody td .kywd-wrap .badge {
  height: 20px !important;
  padding: 0 4px 0 4px !important;
  margin-bottom: 4px;
}

.crtr-nm {
  padding-top: 1px;
}
.table > * > * > *[colspan="2"] {
  border-right: none !important;
}
tr > *[colspan="2"] .th-innr {
  line-height: 22px;
}

.table tbody tr:last-child > * {
  border-bottom: 1px solid gray;
}

/* fullscreen control */
.scnd-sprt-titl {
  width: 100%;
  height: fit-content;
  text-align: start;
  padding: 5px 62px 5px 7px;
  max-height: 72px;
}

.pc-bttm-area {
  width: 100%;
  padding: 0 0 3px 1rem;
  display: none;
}

.pc-bttm-area > *:not(.lb-logo-gen8) {
  padding: 10px 0.25rem;
  line-height: 22px;
}

.pc-bttm-area .badge {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.pc-bttm-area .badge.bdge-size {
  cursor: unset;
}
.lb-logo-gen8 {
  width: fit-content;
}

.lb-logo-gen8 > img {
  height: 36px;
}

.txt-citt {
  height: 36px;
  overflow: hidden;
  color: white;
  font-size: 0.9rem;
  line-height: 36px;
  padding-left: 1rem;
}

.btn-max {
  width: 48px;
  display: none;
}

.btn-max > svg {
  height: 26px;
}

.work-info-pane {
  margin-bottom: 64px;
}

@media (max-width: 991px) {

  .col-right {
    border-top: 2px solid rgba(255,255,255,0.5);
    border-left: none;
    padding-bottom: 132px;
  }
  .img-ctnt-wrap {
    height: 100%;
    overflow: hidden;
  }

  .row-crtr-info {
    margin-right: 0.5rem;
  }

  .art-title {
    font-size: 1.1rem;
    padding: 0.7rem 0.7rem 0 0.7rem;
  }

  .btn-dnld {
    margin: auto;
  }

  .col-crtr {
    text-align: end;
    padding-left: 0.6rem;
  }
  .table tbody .th-innr .th-innr-right .badge.rounded-pill {
    line-height: 22px;
  }

  .crtr-info {
    margin-right: 9px;
  }
  .fullscreen .scnd-sprt-titl {
    padding-right: 5px;
  }

  .fullscreen .kybd-wrap {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translateX(-50%);
  }

  .fullscreen .kybd-wrap .ic-arow-ud {
    display: none;
  }

}

@media (max-width: 767.98px) {
  .ctnt-wrap {
    height: calc(100% + 128px);
  }
  .col-left {
    padding: 0 0.5rem;
  }
}

@media (min-width: 768px) {
  .table {
    border-right: none;
  }
}

@media (min-width: 992px) {

  .fullscreen .kybd-wrap {
    display: block;
    position: absolute;
    right: 143px;
    top: 50%;
    transform: translateY(-50%);
  }

  .fullscreen .pc-bttm-area > *:not(.lb-logo-gen8) {
    padding: 20px 0.25rem 0;
    line-height: 22px;
  }

  .row-crtr-info {
    margin-right: 42px;
  }

  .col-crtr {
    padding-right: 32px;
  }

  .fullscreen .kybd-wrap .ic-arow-lr {
    display: none;
  }

  .ctnt-wrap {
    height: 100%;
  }

  .crtr-info {
    margin-right: 42px;
  }

  .scnd-sprt-titl {
    display: none;
  }

  .fullscreen .btn-max {
    display: block;
  }
  .fullscreen .lb-logo-gen8, .fullscreen .txt-citt  {
    margin-top: 12px;
  }
  .left-cornered h5 {
    padding-left: 0;
  }

  .art-title {
    padding-top: 0.5rem;
    margin-right: 44px;
    word-break: break-all;
  }

  .table-wrap {
    padding-left: 0;
    padding-right: 0;
    min-height: 100%;
  }

  .td-kywd-wrap {
    padding-left: 0.5rem;
    overflow-y: auto;
  }

  .col-right {
    max-height: calc(100% - 63px);
    overflow-y: auto;
  }
  .col-right.full {
    max-height: 100%;
    overflow-y: auto;
  }
}

@media (min-width: 1440px) {
  .pc-bttm-area {
    display: flex;
  }
}
</style>
